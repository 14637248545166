import { Session } from '@supabase/supabase-js'
import { useQueryClient } from '@tanstack/react-query'
import { randomUUID } from 'crypto'
import { H } from 'highlight.run'
import { useRouter } from 'next/router'
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { supabaseComponentClient } from '@/plugins/supabase'
import { SupabaseUserMetadata } from '@/models'
import { apiClient } from '@/services/api/client'
import {
  APP_LOCAL_STORAGE_KEY_PREFIX,
  DEFAULT_AUTHENTICATED_PAGE,
} from '@/constants'
import { useCurrentUser } from '@/hooks'

import { AuthContext } from './authContext'

const REFRESHING_TIME_GAP_BEFORE_EXPIRE_IN_SECS = 10

const publicPages = ['/login', '/pass-setup', '/pass-reset']

const protectedPages = ['/registration']

const returnToKey = `${APP_LOCAL_STORAGE_KEY_PREFIX}returnTo`

const isPrivatePage = (pathname: string) =>
  !publicPages.includes(pathname) && !protectedPages.includes(pathname)

export function AuthContextProvider({ children }: PropsWithChildren) {
  const router = useRouter()

  const [session, setSession] = useState<Session | null>()
  // TODO: should be a state machine instead of too many flags - useState
  const [isInitialLogIn, setIsInitialLogIn] = useState(true)

  const [isSigningOut, setIsSigningOut] = useState(false)

  const [isSignedOut, setIsSignedOut] = useState(false)

  const queryClient = useQueryClient()

  const { currentUser } = useCurrentUser()

  const userMetadata = useMemo(
    () => (session?.user?.user_metadata || {}) as SupabaseUserMetadata,
    [session],
  )

  const queryReturnTo = router.query?.returnTo

  const updateToken = useCallback(
    async (
      newAccessToken: string,
      onSuccess?: () => void,
      newRefreshToken?: string,
    ) => {
      const refreshToken = newRefreshToken || session?.refresh_token
      if (!refreshToken) return

      const { error } = await supabaseComponentClient.auth.setSession({
        access_token: newAccessToken,
        refresh_token: refreshToken,
      })

      if (error) {
        console.error('Failed to update token', error)
        return
      }

      onSuccess?.()
    },
    [session],
  )

  const getSessionAsync = useCallback(async () => {
    const { data: session, error } =
      await supabaseComponentClient.auth.getSession()

    if (error || !session) {
      console.error('Failed to get session', error)
      setSession(null)
      return
    }
  }, [])

  const scheduleTokenRefresh = useCallback((rawSession: Session) => {
    const expirationTime =
      rawSession.expires_in - REFRESHING_TIME_GAP_BEFORE_EXPIRE_IN_SECS
    const refreshTimeInMs = expirationTime * 1000

    const scheduleTimeout = setTimeout(async () => {
      const { error } = await supabaseComponentClient.auth.refreshSession()

      if (error) {
        console.error('Failed to refresh session - Signing out', error)
        supabaseComponentClient.auth.signOut()
      }
    }, refreshTimeInMs)

    return scheduleTimeout
  }, [])

  const goToInitialPage = useCallback(() => {
    const returnTo = localStorage.getItem(returnToKey)

    if (returnTo) {
      router.push(returnTo).then(() => {
        localStorage.removeItem(returnToKey)
      })
      return
    }

    const pathname = router.pathname

    if (!isPrivatePage(pathname) || pathname === '/login' || pathname === '/') {
      router.push(`/${DEFAULT_AUTHENTICATED_PAGE}`)
    }
  }, [router])

  const jwt = useMemo(() => session?.access_token, [session?.access_token])

  const getCurrentToken = useCallback(() => session?.access_token, [session])

  const intercomData = useMemo(() => {
    return {
      app_id: 'jvogfp1x',
      ...(currentUser
        ? {
            email: currentUser?.email,
            user_id: currentUser?.id,
          }
        : {}),
    }
  }, [currentUser])

  const isPublicPage = useCallback(
    (pathname: string) => publicPages.includes(pathname),
    [],
  )

  const isProtectedPage = useCallback(
    (pathname: string) => protectedPages.includes(pathname),
    [],
  )

  useEffect(() => {
    getSessionAsync()

    supabaseComponentClient.auth.stopAutoRefresh()

    const {
      data: { subscription },
    } = supabaseComponentClient.auth.onAuthStateChange((event, session) => {
      if (event === 'INITIAL_SESSION') {
        setSession(session)
        setIsInitialLogIn(false)

        if (
          !session &&
          !publicPages.includes(router.pathname) &&
          !isSignedOut
        ) {
          let returnTo

          if (queryReturnTo) {
            returnTo =
              queryReturnTo instanceof Array ? queryReturnTo[0] : queryReturnTo
          } else if (
            isPrivatePage(router.pathname) &&
            router.asPath !== '/' &&
            router.asPath
          ) {
            returnTo = router.asPath
          }

          if (returnTo) {
            localStorage.setItem(returnToKey, returnTo)
          }

          router.replace('/login')
        }
      } else if (event === 'SIGNED_IN') {
        setSession(session)
        const isSameSession = session?.access_token === jwt

        if (
          !isSameSession &&
          (!publicPages.includes(router.pathname) ||
            router.pathname === '/login')
        )
          goToInitialPage()
      } else if (event === 'TOKEN_REFRESHED') {
        setSession(session)
        queryClient.cancelQueries()
      } else if (event === 'SIGNED_OUT') {
        setSession(null)
        queryClient.clear()
        setIsSigningOut(false)
        setIsSignedOut(true)
        router.replace('/login')
      } else if (event === 'USER_UPDATED') {
        router.replace(`/`)
      }

      if (session?.user?.email) {
        H.identify(session.user.email, {
          id: session.user.id,
        })
      } else if (randomUUID) {
        H.identify('UNKNOWN_USER', {
          id: randomUUID(),
        })
      }
    })

    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryReturnTo, getSessionAsync, jwt, isSignedOut, router.pathname])

  useEffect(() => {
    if (!session) return

    const scheduleStopper = scheduleTokenRefresh(session)

    return () => {
      !!scheduleStopper && clearTimeout(scheduleStopper)
    }
  }, [session, scheduleTokenRefresh])

  useEffect(() => {
    if (!session) queryClient.clear()
  }, [session, queryClient])

  useEffect(() => {
    if (window.Intercom) window.Intercom('boot', intercomData)
  }, [intercomData])

  useEffect(() => {
    if (!window.Intercom) return

    const handleRouteChange = () => {
      window.Intercom('update', intercomData)
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router, intercomData])

  useEffect(() => {
    if (jwt) {
      apiClient.setAuthorizationHeader(jwt)
    }
  }, [jwt])

  return (
    <AuthContext.Provider
      value={{
        jwt,
        userMetadata,
        session,
        updateToken,
        isInitialLogIn,
        isSigningOut,
        setIsSigningOut,
        getCurrentToken,
        isPublicPage,
        isProtectedPage,
        goToInitialPage,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
